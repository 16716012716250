.post_content, .variant_content {
  + .tox:not(.tox-tinymce-inline) {
    border-color: #fff;
    border-width: 1px;

    .tox-editor-container {
      .tox-editor-header {
        background-color: inherit;
        border-color: #fff;

        .tox-toolbar-overlord {
          background-color: inherit;

          .tox-toolbar__primary {
            background-color: inherit;
          }
        }
      }
      .tox-throbber {
        background-color: inherit;
      }
    }

    iframe {
      background-color: inherit;
    }
  }

  &[disabled] {
    + .tox:not(.tox-tinymce-inline) {
      @apply border-grey-1;

      .tox-editor-header {
        @apply border-grey-1;
      }
    }
  }
}