@use "node_modules/tom-select/src/scss/tom-select.default";

.default-input-class{
  @apply flex items-center rounded-7px px-3 h-10 outline-none border border-gray-10 focus:border-orange-20 bg-transparent hover:bg-gray-45;
}

@mixin default-input-klass {
  @apply p-2 w-full rounded-8px text-16px leading-6 text-white border border-white outline-none;

  background: none;

  &:hover {
    background: var(--color-grey-4);
  }

  &:focus, &:focus-within {
    border-color: var(--color-blue-10) !important;
  }
}

input {
  &.default-input-klass{
    @include default-input-klass;

    @apply h-10;

    &.search-input {
      @apply bg-grey-4 border-grey-4 pl-10;

      & + i.icon {
        @apply absolute left-0 top-0 w-6 h-6 ml-2 mt-2;

        background-image: url('~images/icons/search.svg');
        background-repeat: no-repeat;
      }
    }

    &.link-input {
      @apply pl-10;

      & + i.icon {
        @apply absolute left-0 top-0 w-6 h-6 ml-2 mt-2;

        background-image: url('~images/link.svg');
      }
    }

    &#date-start, &#date-end {
      @apply w-32 sm:w-40  lg:w-56
    }

    &::placeholder {
      color: var(--color-grey-1) !important;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--color-grey-1) !important;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--color-grey-1) !important;
    }

    &[disabled] {
      @apply text-grey-1 border-grey-1;
    }
  }
}

div.error {
  input,div  {
    &.default-input-klass {
      @apply border-danger mb-0;

      ~ span.error-msg {
        @apply top-full text-danger text-12px;
      }
    }
  }
}

textarea {
  &.default-input-klass{
    @include default-input-klass;

    &[disabled] {
      @apply border-grey-1;
    }
  }
}

select, input {
  &.default-input-klass{
    + .ts-control {
      @include default-input-klass;

      @apply flex h-10 items-center text-12px;
      min-height: 2.25rem;

      &:not(.input-hidden) {
        > .ts-dropdown {
          display: block;
          visibility: visible;
        }
      }

      &.hidden {
        display: none;
      }

      .ts-input {
        @apply p-0 min-h-[28px] border-0 shadow-none text-16px text-white font-normal items-center;

        background: none;

        input {
          @apply text-white;
          opacity: 0;
        }

        > .item {
          @apply truncate pr-6;
        }

        &:after {
          position: absolute;
          top: 10px;
          right: 10px;
          height: 10px;
          width: 10px;
          border: 2px solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          transform-origin: 75% 75%;
        }

        &.input-active {
          @apply border-0;

          background: none;

          &:after{
            border: 2px solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(225deg);
          }
        }
        &.dropdown-active::before {
          @apply h-0;
        }
      }

      &.plugin-remove_button .ts-input {
        &.items {
          @apply -m-1 p-0;
        }

        .item[data-value] {
          @apply flex items-center m-1 border-0 rounded pl-2 pr-4 py-1 h-5 bg-gray-30 bg-none text-gray-10 text-12px font-medium;

          .remove {
            @apply border-0 pt-0 h-5;
          }
        }
      }

      .ts-dropdown {
        @apply rounded-8px border-grey-2 bg-grey-2 text-16px text-white font-normal overflow-hidden;

        left: -1px;
        box-sizing: content-box;
        box-shadow: 0px 5px 10px 1px rgba(0,0,0,.25);
        z-index: 50;

        .active {
          background-color: inherit;
          color: inherit;
        }

        .selected {
          @apply relative text-orange-20 bg-grey-2;

          &:after{
            @apply absolute border-orange-20;

            content: '';
            width: 7px;
            height: 10px;
            border-width: 0 2px 2px 0;
            right: 17px;
            top: 10px;
            transform: rotate(45deg);
          }
        }

        .option{
          @apply truncate pr-8;

          &:hover {
            @apply text-orange-20 bg-grey-2;
          }
        }

        .ts-dropdown-content {
          &::-webkit-scrollbar, &::-webkit-scrollbar-thumb {
            display: none;
          }
        }
      }
    }

    &:not([data-searchable]):not([data-creatable]) {
      + .ts-control {
        cursor: pointer;

        .ts-input {
          cursor: pointer;
        }
        input {
          display: none !important;
        }
      }
    }

    &[data-searchable] {
      + .ts-control {
        .ts-input {
          input {
            @apply text-14px;
            opacity: 1;
          }
        }
      }
    }

    &[data-creatable] {
      + .ts-control {
        .ts-input {
          > .item {
            @apply pr-1;
           }
          input {
            opacity: 1;
          }
        }
      }
    }

    &[data-multiple] {
      + .ts-control {
        height: auto;
        min-height: 40px;

        .ts-input {
          &:after {
            display: none;
          }
        }
        .ts-dropdown {
          .active, .create {
            @apply text-white;

            &:after{
              display: none;
            }
          }
          .option, .create {
            &:hover {
              @apply text-orange-20 bg-grey-2;
            }
          }
        }
      }
    }

    &[data-searchable][data-multiple] {
      + .ts-control {
        .ts-input {
          input {
            margin-left: 0 !important;
            padding-left: 4px !important;
          }
        }
      }
    }

    &:disabled {
      &:hover {
        cursor: not-allowed;
      }

      + .ts-control {
        @apply border-grey-1;
        cursor: not-allowed !important;

        .ts-input {
          @apply text-grey-1 opacity-100;

          cursor: not-allowed !important;
        }
      }
    }
  }
}

.analytics {
  input {
    &.default-input-klass{
      @include default-input-klass;
      @apply h-10;
      &#date-start, &#date-end {
        @apply sm:w-32 lg:w-32 h-8
      }
    }
  }
  .default-input-klass {
    &.analytics-post{
      width: 304px ;
    }
    &.ts-control{
      min-height: 2rem;
      height: 2rem;

      &:not(.input-hidden) {
        border-color: #2BDEE9;
      }
      &.date-range {
        @apply sm:w-full sm:w-28 h-8
      }
    }
  }
}


.language-switch {
  .default-input-klass {
    &.ts-control{
      width: 5rem;
      min-height: 1.5rem;
      height: 1.5rem;
    }
  }
}


#advance_filter {
  .default-input-klass {
    &:focus, &:focus-within {
      border-color: #E94C2B !important;
    }
  }
}

input[type=checkbox] {
  + label.square-box {
    @apply relative border w-5 h-5 md:w-6 md:h-6 rounded-[3px];
  }

  &:checked + label.square-box {
    &:after {
      @apply absolute top-1/2 left-1/2 w-3.5 h-3.5 bg-[#ffffff];
      content: '';
      transform: translate(-50%, -50%);
    }
  }
}
.radio-group {
  @apply flex gap-5;

  .radio-item {
    @apply relative h-10;

    input[type=radio] {
      @apply absolute invisible;
    }

    label {
      @apply block relative py-2 pl-10 pr-2 cursor-pointer;
      transition: all 0.25s linear;
    }

    .check {
      @apply block absolute border-2 rounded-full h-5 w-5 top-[10px] left-[10px] z-[5];
      transition: border 0.25s linear;

      &::before {
        @apply block absolute rounded-full h-3 w-3 top-0.5 left-0.5 m-auto;
        content: '';
        transition: background 0.25s linear;
      }
    }

    input[type=radio]:checked {
      ~ .check {
        &::before {
          @apply bg-orange-20;
        }
      }
    }
  }
}

.checkbox-item {
  @apply relative h-10;

  input[type=checkbox] {
    @apply absolute invisible;
  }

  label {
    @apply block relative py-2 pl-10 pr-2 cursor-pointer;
    transition: all 0.25s linear;
  }

  .check {
    @apply block absolute border-2 rounded h-6 w-6 left-[10px] z-[5];
    transition: border 0.25s linear;

    &::before {
      @apply block absolute rounded-[1px] h-3 w-3 top-1 left-1 m-auto;
      content: '';
      transition: background 0.25s linear;
    }
  }
}

.selected {
  .checkbox-item {
    input[type=checkbox] {
      ~ .check {
        &::before {
          @apply bg-white;
        }
      }
    }
  }
}
