@mixin dropdown-triangle-klass {
  @apply absolute;
  content: '';
  display: block;
  top: 0.5rem;
  width: 1rem;
  height: 1rem;
  background: #4A4C50 ;
  border-top-right-radius: 25%;
  transform: rotate(-53deg) skewX(-15deg);
}

@keyframes slideIn {
  from {
    top: 100%;
  }
  to {
    top: 25%;
  }
}

@keyframes slideOut {
  from {
    top: 25%;
  }
  to {
    top: 100%;
  }
}

[data-nav-dropdown-target],[data-nav-dropdown-panel-target] {
  &.nav-dropdown-mask {
    @apply absolute z-20 left-1/2;
    transform: translateX(-70%);

    @media (min-width: 560px) {
      transform: translateX(-50%);
    }

    &::before {
      @include dropdown-triangle-klass;
      left: 46.5%;
    }
  }
  &.nav-dropdown-mask-component {
    @apply absolute z-20 left-1/2;

    transform: translateX(-50%);

    &::before {
      @include dropdown-triangle-klass;
      left: 46.5%;
    }
  }

  &[data-nav-dropdown-direction='left'] {
    &.nav-dropdown-mask {
      @apply -left-2;

      transform: translateX(0%);

      &::before {
        @apply left-3;
      }
    }
  }

  &[data-nav-dropdown-direction='right'] {
    &.nav-dropdown-mask {
      @apply left-auto -right-2;

      transform: translateX(0%);

      &::before {
        @apply left-auto right-3;
      }
    }
  }

  .nav-dropdown-wrap {
    @apply rounded-8px shadow-27px;
  }

  .nav-dropdown {
    @apply  relative p-2 mt-4 text-gray-10 font-medium rounded-8px;
    @apply  bg-[#4A4C50];
  }
}

.notify-mask {
  &::before {
    @screen sm {
      @include dropdown-triangle-klass;
      right: 4.25rem;
    }
  }
}

@media (max-width: 705px) {
  :not(.close) .notify-mask {
    animation: slideIn .4s cubic-bezier(0,1.3,.91,.92) forwards;
  }
}

.analytics {
  [data-nav-dropdown-target],[data-nav-dropdown-panel-target] {
    &.nav-dropdown-mask {
      @apply left-20 sm:left-1/2;
      
      @media (min-width: 560px) {
        transform: translateX(-50%);
      }
  
      &::before {
        @include dropdown-triangle-klass;
        left: 46.5%;
      }
    }
  }
  .infinity .nav-dropdown-mask {
    @apply left-[-35px] sm:left-[-16px];

    &::before {
      @apply left-[80%] sm:left-[53%];
    }
  }
}
.payout-hint {
  [data-nav-dropdown-target],[data-nav-dropdown-panel-target] {
    &.nav-dropdown-mask {
      @apply left-20 ;
      
      @media (min-width: 560px) {
        transform: translateX(-50%);
      }
  
      &::before {
        @include dropdown-triangle-klass;
        left: 10%;
      }
    }
  }
}