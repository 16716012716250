#profile-collection {
  @apply flex flex-col p-6 bg-grey-7 rounded-xl;
}

#profile-item-title {
  @apply mb-2;

  span {
    @apply text-primary italic text-16px font-medium;
  }
}

#profile-item-collection {
  @apply grid gap-4 smd:grid-cols-5 md:grid-cols-6 2xl:grid-cols-8;

  .profile-item-not-found, .profile-item-loading {
    @apply smd:col-span-3 md:col-span-4 lg:col-span-5 2xl:col-span-6 4xl:col-span-8 flex items-center justify-center h-[100px];
  }

  .profile-item {
    @apply relative flex smd:flex-col items-center;

    img {
      @apply w-[44px] h-[44px] smd:w-[96px] smd:h-[96px];
    }
    .profile-name {
      @apply flex-1 pl-4 smd:pl-0 smd:text-center;
    }
    .follow-btn {
      @apply text-12px px-2 rounded-full h-6 cursor-pointer leading-6;
      @apply smd:hidden smd:absolute smd:top-[64px] smd:left-1/2 smd:-translate-x-1/2;

      &.follow {
        @apply btn-primary;
      }
      &.unfollow {
        @apply btn-secondary;
      }
    }

    &:hover{
      a {
        @apply opacity-50;

        ~ .follow-btn {
          @apply smd:block;
        }
      }
    }
  }
}

#profile-follow-collection {
  #profile-item-collection {
    @apply grid gap-4 smd:grid-cols-3 md:grid-cols-4 lg:grid-cols-5;

    .profile-item-container {
      padding: 1rem;
      background-color: #34383d;
      border-radius: 0.75rem;
    }
    .profile-item {
      @apply w-full;
    }
    .profile-follow-btn {
      @apply text-12px px-2 rounded-full h-6 cursor-pointer leading-6;

      &.follow {
        @apply btn-primary;
      }
      &.unfollow {
        @apply btn-secondary;
      }
    }

    &:hover{
      a {
        @apply opacity-100;
      }
    }
  }
}

#profile-review-collection  {
  .review-search-form {
    .comment-order-type {
      @apply flex items-center gap-x-2;

      .icon {
        @apply inline-block w-5 h-5;
        content: '';
        background-image: url('~images/icons/sort.svg');
      }

      label {
        @apply text-16px font-medium cursor-pointer leading-[20px];
      }

      input:checked + label {
        @apply text-primary;
      }
    }
  }
  .icon-container {
    margin-top: 16px;

    @media (min-width: 560px) {
      margin-top: 32px;
    }
    @media (min-width: 784px) {
      margin-top: 80px;
    }
  }
  .icon {
    &.link_to {
      background-size: 20px 20px;
      background-image: url('~images/link_to_no_bg.svg');
    }
  } 
}

#profile_page_collection {
  #post-item-collection .post-item {
    @apply transition-all duration-300;

    &:hover {
      transform: translateY(-5px);
      box-shadow: rgba(38, 38, 38, 0.05) 0px 2px 15px 2px;
    } 
  }
  .designer-collection-form {
    @apply w-full flex flex-col flex-wrap bg-grey-7 rounded-lg;
  
    margin-bottom: -112px;
    min-height: 966px;
    @media (min-width: 385px) {
      margin-bottom: -60px;
    }
    @media (min-width: 560px) {
      margin-bottom: -112px;
      min-height: 866px;
    }
    @media (min-width: 780px) {
      min-height: 826px;
    }
    @media (min-width: 1024px) {
      min-height: 776px;
    }
    @media (min-width: 1280px) {
      min-height: 826px;
    }
    @media (min-width: 1536px) {
      min-height: 900px;
    }
  }
  .review-search-form {
    .search-icon {
      @apply absolute left-0 top-0 w-6 h-6 ml-5 mt-2 hover:cursor-pointer;
      
      background-image: url('~images/icons/search.svg');
      filter: brightness(.5);
      background-repeat: no-repeat;
    }
    .review-search-bar {
      padding-left: 56px;
    }
    .review-search-bar::-webkit-search-cancel-button {
      filter: invert(50%) sepia(20%) saturate(200%) hue-rotate(180deg) brightness(50%) contrast(90%);
      cursor: pointer;
    }
    .comment-order-type {
      @apply flex items-center gap-x-2;

      .icon {
        @apply inline-block w-5 h-5;
        content: '';
        background-image: url('~images/icons/sort.svg');
      }

      label {
        @apply text-14px font-medium cursor-pointer leading-[20px];
      }

      input:checked + label {
        @apply text-primary;
      }
    }
  }

  .collection-more {
    .more-content {
      @apply hidden rounded-8px overflow-hidden top-[32px] z-10;
    }

    .icon.more-icon {
      @apply inline-block w-6 h-6 hover:bg-grey-1 rounded-full;

      background-image: url('~images/icons/More.svg');
    }

    .icon.edit-icon {
      @apply inline-block w-6 h-6;

      background-image: url('~images/icons/NotePencil.svg');
    }
    .icon.delete-icon {
      @apply inline-block w-6 h-6;

      background-image: url('~images/icons/trash.svg');
    }

    button {
      @apply px-4 py-2 bg-grey-2 text-grey-5 text-14px font-medium hover:bg-grey-1;
    }

    input:checked {
      ~ label .icon {
        @apply bg-grey-1;
      }
      ~ .more-content {
        @apply block;
      }
    }
  }

  .selected {
    border-radius: 8px;
    border: 2px solid #2BDEE9
  }
}