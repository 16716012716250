.newpost {
  background: var(--newpost-background);
  width: calc(100% - 1.25rem);

  @apply rounded-7px px-4 min-w-modal shadow-15px;

  @screen md {
    @apply w-container rounded-10px pl-12 pr-20;
    &:hover {
      background: var(--newpost-hover-background);
    }
  }
  .text {
    @apply flex flex-col justify-center h-auto rounded-5px border py-1.25 px-3 my-5 font-light flex-auto border-gray-10;
    @screen md {
      @apply rounded-7px px-7 py-3.5 my-7;
    }
  }
}
