.profile-navbar {
  .navbar-link {
    &:hover:not(.active) {
      @apply bg-grey-7 text-grey-3;

      .icon {
        @apply bg-grey-3;
      }
    }
    &.active {
      @apply bg-grey-7 relative;

      overflow: hidden;

      &:after {
        @apply absolute bg-primary w-1.5 h-full top-0 left-0;
        content: '';
      }
    }
  }

  .mobile-navbar-link {
    .tab {
      border-color: rgba(0,0,0,0);
    }

    &.active .tab {
      @apply border-primary;
    }
  }

  .checkbox-dropdown {
    display: none;

    + label {
      @apply cursor-pointer relative;

      &:after {
        @apply w-3 h-3 absolute right-4 top-1;
        @apply transition-all duration-300;
        content: '';
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
      }
    }
    ~ .dropdown-content {
      max-height: 0;
      overflow: hidden;
    }

    &:checked {
      + label {
        &:after {
          @apply top-2.5;
          transform: rotate(135deg);
        }
      }

      ~ .dropdown-content {
        max-height: 300px;
      }
    }
  }
}

.mobile-comments-navbar-link {
  &.tab {
    border-color: rgba(0,0,0,0);
  }

  &.active  {
    font-weight: 500;
    &.tab {
      @apply border-primary;
    }
  }
}
