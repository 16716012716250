.cookie-modal-container {
  a {
    @apply underline;
  }
  .btn {
    @apply mb-2 bg-orange-20 text-white text-base px-4 py-2 rounded-md cursor-pointer;
    flex: 1 1 100%;

    &:hover {
      @apply bg-orange-10;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .btn.reject {
    @apply bg-grey-3;

    &:hover {
      @apply bg-grey-5;
    }
  }
  
  .cookie-modal {
    @media (max-width: 768px) {
      width: calc(100% - 2rem);
    }
  }
}