.posts-show {
  .ellipsis-btn {
    &:hover {
      cursor: pointer;
    }
  }
}

.tooltip {
  @apply absolute bg-gray-30 text-white text-14px text-center rounded-7px z-10 ;
  @apply mb-2 pt-2 px-2 h-10 w-28 md:-ml-9 -ml-12;
  visibility: hidden;
  left: 52%;
  top: -25%;

  @media (min-width: 560px) {
    left: 62%;
    top: 95%;
    &::after {
      content: "";
      position: absolute;
      top: -25%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color:  transparent transparent #575D65 transparent;
    }
  }
  @media (min-width: 780px) {
    left: 57%;
  }
}
