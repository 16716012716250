.swiper {
  .swiper-wrapper {
    .swiper-slide {
      position: relative;

      &.campaign-banner {
        aspect-ratio: 24/7;
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
      }
    }
  }
  .swiper-button-prev {
    left: 0;
    // background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
  }
  .swiper-button-next {
    right: 0;
    // background: linear-gradient(-90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
  }
  .swiper-button-prev, .swiper-button-next {
    height: 100%;
    width: 80px;
    top: 0;
    margin-top: 0;
    color: white;
    &:after {
      padding: 10px;
      font-size: 1.5rem;
      background-color: rgba(0,0,0,0.1);
    }
  }
  .swiper-pagination-bullet {
    @apply bg-orange-20
  }
  .swiper-pagination-bullet-active {
    // @apply bg-orange-20
  }
}
@media (max-width: 767px) {
  .swiper {
    .swiper-wrapper {
      .swiper-slide {
        &.campaign-banner {
          aspect-ratio: 5/2;
        }
      }
    }
    .swiper-button-prev, .swiper-button-next {
      width: 2rem;
      &:after {
        font-size: 1rem;
      }
    }
  }
}

.home-images-slide {
  position: relative;

  .swiper-slide {
    opacity: 1;
    transition: opacity ease-in-out .2s;
  }

  .swiper-button-prev, .swiper-button-next {
    @apply hidden sm:block absolute rounded-full w-7 h-7 p-1 sm:w-10 sm:h-10 sm:p-2 border-white border-2 hover:bg-grey-3 hover:opacity-[1];
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    opacity: .3;
    transition: opacity ease-in-out .2s;
  }

  .swiper-button-prev {
    @apply left-3 smd:left-8;
  }

  .swiper-button-next {
    @apply right-3 smd:right-8;
  }

  .swiper-pagination {
    @apply z-10 absolute right-1/2 bottom-1 sm:bottom-5 translate-x-1/2 transition-opacity ease-in-out duration-200;

    span {
      @apply w-2.5 h-2.5 m-1.5 sm:m-2.5 rounded-full bg-black bg-opacity-25 inline-block;
    }
    .swiper-pagination-bullet-active {
      background: rgba(0,0,0,0.5);
    }
  }
}