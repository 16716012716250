@mixin default-scrollbar-klass {
  &::-webkit-scrollbar {
    @apply rounded-8px w-2 h-full;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-8px bg-grey-2;
    border: 1px solid var(--color-grey-5);
  }
}

/* 針對 iOS 設備的樣式（覆蓋預設樣式） */
@supports (-webkit-touch-callout: none) {
  .ios-scrollbar {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
}

.scrollbar {
  @include default-scrollbar-klass;

  scrollbar-gutter: stable;
}

.scrollbar-gutter-auto {
  @include default-scrollbar-klass;

  scrollbar-gutter: auto;
}

@media (min-width: 705px) {
  .smd\:scrollbar {
    @include default-scrollbar-klass;
    scrollbar-gutter: stable;
  }
}