#cart-promotion, #cart-coupon {
  .items-content {
    @apply max-h-0 transition-all duration-300;
  }

  .discount-item {
    @apply hover:bg-grey-2 cursor-pointer;

    .item-code {
      line-height: 20px;
    }
    .item-price, .item-expires {
      line-height: 16px;
    }
  }

  input[type=radio] {
    @apply hidden;

    &:checked {
      ~ form .items-content {
        @apply mb-2 max-h-fit smd:max-h-[276px];
      }
    }
  }

  .discount-item.selected{
    border: 1px solid #2BDEE9;
    box-shadow: inset 0 0 0 1px #2BDEE9;
  }
}

#cart-promotion {
  .collapse-btn {
    @apply absolute w-6 h-6 z-10 top-2 right-2 bg-contain cursor-pointer;

    content: '';
    background-image: url('~images/icons/profile-menu.svg');
  }

  input[type='text'] {
    @apply pr-10 pl-2 text-14px smd:text-12px md:text-14px;

    + .error-msg {
      @apply block mt-2;
    }
  }
  input[type=radio]:checked ~ form .relative .collapse-btn {
    transform: rotate(180deg);
  }
}

#cart-coupon {
  input[type=radio]:checked {
    ~ form .coupon-collaspe-btn {
      @apply hidden;
    }
    ~ form .btns {
      @apply flex;
    }
  }
}

.campaign-banner {
  @apply w-full overflow-hidden rounded-lg bg-clip-padding;

  background-size: auto 162px;

  @media (min-width: 560px) {
    @apply bg-left bg-center;
    background-size: auto 110px;
  }
}

#cart_index {
  .free-tag {
    color: #19791D;
    background-color: #2CFF67;
    border-radius: 4px;
    line-height: 16px;
  }
  
  .pv-tag {
    background-color: #E60044;
    border-radius: 4px;
    line-height: 16px;
  }
}