$padding-level-1: 2rem;
$padding-level-2: 2.5rem;
$padding-level-3: 3rem;
$counter-width: 2em;

.agreement-list {
  list-style: none;
  counter-reset: section;

  > li {
    counter-increment: section;
    position: relative;

    &::before {
      content: "";
    }

    &.list-10 {
      padding-left: $padding-level-2 !important;
    }

    .agreement-list > li {
      padding-left: $padding-level-1;

      &::before {
        content: counters(section, ".") " ";
        position: absolute;
        left: 0;
        width: $counter-width;
      }

      .agreement-list > li {
        padding-left: $padding-level-3;
      }
    }
  }

  .agreement-list {
    counter-reset: section 0;
  }
}