.order-success {
  @apply flex flex-col w-full h-[70vh] md:h-full justify-center items-center;
  .first-title {
    @apply mb-8 text-center text-4xl md:text-5xl;
  }
  .second-title {
    @apply mb-12 w-2/3 text-center text-base md:text-xl;
  }
  .content-container {
    @apply flex flex-row mb-16 justify-center items-center w-[70%] md:w-[450px];
    
    img {
      @apply w-9 h-9 mr-8;
    }
    p {
      color: var(--color-grey-5);
    }
  }
  hr {
    @apply mb-16 w-[80%] md:w-1/2 lg:w-[700px];
    border-color: var(--color-grey-3);
  }
  .download-btn {
    @apply rounded-md bg-orange-20 hover:bg-orange-10;
    padding: 8px 16px;
  }
}