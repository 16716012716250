.page-title {
  @apply relative text-center text-24px font-bold capitalize py-3 md:py-4 mb-3 md:mb-7;

  &::after {
    content: '';

    transform: translateX(-50%);

    @apply absolute bottom-0 left-1/2 bg-primary block text-center w-14 md:w-20 h-1;
  }
}

.search-page-title {
  @apply relative font-bold text-2xl lg:text-4xl capitalize;
}

.explore {
  @apply relative py-1 pl-3 pr-7 rounded-lg border border-gray-10 text-sm md:text-lg hover:text-orange-20;

  &:after {
    @apply absolute right-3 border-l-2 border-b-2 border-white;
    content: "";
    height: 10px;
    width: 10px;
    transform: rotate(-135deg) translate(-60%, -60%);
  }

  &:hover{
    @apply border-orange-20;

    &:after{
      @apply border-orange-20;
    }
  }
}

@media (max-width: 767px) {
  .explore {
    &:after{
      height: 8px;
      width: 8px;
      transform: rotate(-135deg) translate(-50%, -50%);
    }
  }
}
