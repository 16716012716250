.line-clamp-1,
.line-clamp-2,
.line-clamp-3 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  @apply overflow-hidden;
}

.line-clamp-1 { -webkit-line-clamp: 1; }
.line-clamp-2 { -webkit-line-clamp: 2; }
.line-clamp-3 { -webkit-line-clamp: 3; }
