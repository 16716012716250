.post-info {
  &-dropdown-mask {
    &::before {
      content: '';
      display: block;
      top: 1.2rem;
      right: 1.4rem;

      width: 1.5rem;
      height: 1.5rem;

      background: var(--color-gray-30);
      border-top-right-radius: 25%;

      transform: rotate(-53deg) skewX(-15deg);

      @apply absolute;
    }

    &:hover {
      @apply block;
    }
  }
}
