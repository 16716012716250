@mixin linear-gradient {
  background: linear-gradient(125deg, #ED3D4B 10%, #7F39FE 110%);
}

@mixin text-bg-gradient {
  @include linear-gradient;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}

@mixin fadeInAnimation($startDelay, $endDelay) {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  #{$startDelay} {
    opacity: 0;
    transform: translateY(50px);
  }
  #{$endDelay} {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  @include fadeInAnimation(0%, 50%);
}

@keyframes fadeIn2 {
  @include fadeInAnimation(15%, 65%);
}

@keyframes fadeIn3 {
  @include fadeInAnimation(30%, 80%);
}

@keyframes fadeIn4 {
  @include fadeInAnimation(45%, 90%);
}

@keyframes btnAnimate {
  0% {
    bottom: 64px
  }

  50% {
    bottom: 80px
  }

  100% {
    bottom: 64px
  }
}

$columns-transition-delay: 0.25s;

#home-intro {
  @apply overflow-auto md:overflow-hidden;

  .home-intro-swiper-wrapper {
    @apply h-auto md:h-full;

    @media (max-width: 779px) {
      transform: none !important;
    }

    .swiper-slide {
      @media (max-width: 779px) {
        height: auto !important;
      }
    }
  }
}

#trending_model {
  @apply overflow-hidden;

  span {
    @include text-bg-gradient;
  }

  .mask-bg {
    mask: linear-gradient(0deg, transparent 0%, #000 50%);
  }

  .text-container {
    @apply mt-[12vw] smd:mt-[14vw] md:mt-auto;
    @apply translate-y-[12vw] smd:translate-y-[14vw] md:translate-y-[141px];

    .content {
      animation: fadeIn 4s;
    }
  }

  .block-container {
    @apply mb-[-18vw] smd:mb-[-20vw] md:mb-0;

    .flex {
      @apply gap-[16px] smd:gap-[24px] md:gap-[32px];

      &.flex-col {
        &:nth-child(2), &:nth-child(3) {
          @apply mt-[18vw] smd:mt-[20vw] md:mt-[194px];
        }

        &:nth-child(1) .block_item {
          animation: fadeIn 4s;
        }

        &:nth-child(2) .block_item {
          animation: fadeIn2 4s;
        }

        &:nth-child(3) .block_item {
          animation: fadeIn3 4s;
        }

        &:nth-child(4) .block_item {
          animation: fadeIn4 4s;
        }
      }
    }

    .block_item {
      @apply w-[36vw] smd:w-[40vw] md:w-[356px] bg-cover bg-center aspect-square rounded-[16px];
    }
  }

  .next-btn {
    @include linear-gradient;

    @apply w-12 h-12 absolute left-1/2 bottom-[64px] rounded-full cursor-pointer;

    transform: translateX(-50%);
    animation: btnAnimate 2.5s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
  }
}

#explore_model {
  .image a:hover{
    background: rgba(105, 105, 105, 0.60);
  }
  .content {
    @include linear-gradient;
  }

  .btn {
    @apply inline-block px-4 py-2.5 text-18px leading-6 font-medium bg-[#303234] hover:bg-[#535354] rounded-[30px];

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

#trending_designer {
  .title-content {
    @include text-bg-gradient;
  }

  .btn {
    @apply inline-block px-4 py-2.5 text-18px leading-6 font-medium bg-[#303234] hover:bg-[#535354] rounded-[30px];

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

#intro_signup {
  .line-footer {
    padding: 0;

    .line-footer-animation {
      position: relative;
    }
  }
}