footer {
  .icon {
    @apply bg-no-repeat bg-center bg-contain;

    &.ft-instagram {
      background-image: url('~images/icons/ft-instagram.svg');
    }
    &.ft-discord {
      background-image: url('~images/icons/ft-discord.svg');
    }
    &.ft-phrozen {
      background-image: url('~images/icons/ft-phrozen.svg');
    }
  }
}

.line-footer-animation {
  transition: bottom 0.75s cubic-bezier(0.5, 0, 0.25, 1);
}

.hidden-footer {
  bottom: -100px;
}
.visible-footer {
  bottom: 0;
}