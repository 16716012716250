input.pointer{
  @apply relative cursor-pointer;
}

[data-show] {
	+ .eye {
		background-size: 50% 50%;
	}

  &[data-show=false] {
    + .eye {
      background-image: url('~images/icons/eye_slash.svg');
    }
  }

  &[data-show=true] {
    + .eye {
      background-image: url('~images/icons/eye.svg');
    }
  }
}

.field_with_errors {
  @apply w-full;
}

// remove autocomplete background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  caret-color: white;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

// radio button style
.custom-input[type="checkbox"],
.custom-input[type="radio"] {
	display: none;

	~ .custom__check {
		display: flex;
		align-items: center;
		height: 16px;
		border: 2px solid white;
		position: relative;
		cursor: pointer;

		&:after {
			content: "";
			display: inline-block;
			position: absolute;
		}
	}

	&:not(.switchbox) {
		~ .custom__check {
			justify-content: center;
			flex: 0 0 16px;
			width: 16px;

			&:after {
				visibility: hidden;
			}
		}
	}

  &.switchbox {
		+ .custom__check {
			width: 32px;

			&:after {
				transform: scale(1.5);
				left: 4px;
			}
		}

		&:not(:checked) {
			~ .custom__check {
				&:after {
					background-color: #000;
				}
			}
		}

		&:checked {
			~ .custom__check {
				&:after {
					left: 20px;
				}
			}
		}
	}

	&:disabled {
		~ .custom__check {
			opacity: 0.3;
			cursor: not-allowed;
		}
	}

	&:checked {
		~ .custom__check {
			background-color: var(--color-gray-40);

			&:after {
				visibility: visible;
			}
		}
	}
}

.custom-input[type="checkbox"] {
	&:not(.switchbox) {
		~ .custom__check {
			border-radius: 0.375rem;
			&:after {
				height: 7px;
				width: 3px;
				margin-top: -1px;
				border-bottom: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: rotate(45deg);
			}
		}
	}
}

.custom-input[type="radio"],
.custom-input[type="checkbox"].switchbox {
	~ .custom__check {
		border-radius: 50rem;
		&:after {
			width: 8px;
			height: 8px;
			background-color: #FA6A4C;
			border-radius: 50rem;
		}
	}
}
