.user-icon .icon {
  &.upload, &.cart, &.inbox, &.notification{
    @apply w-10 h-10;

    &::after {
      @apply absolute w-10 h-10 rounded-full overflow-hidden;

      content: '';
      display: block;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover::after {
      @apply bg-grey-1;
    }
  }

  &.profile-menu {
    @apply w-[14px] h-[14px];
  }
}

.header-upload {
  .icon {
    @apply p-2;
  }
}

.header-menu, .header-notify {
  .nav-dropdown-mask, .notify-mask {
    &::before {
      display: none;
    }
  }
}

.header-notify {
  &:not(.close) .icon{
    &::after {
      @apply bg-grey-2;
    }
  }
}

.header-menu {
  &:not(.close) .avatar-meun{
    @apply bg-grey-2;
  }

  .avatar-meun {
    @apply relative rounded-[100px] hover:bg-grey-2;

    &::before {
      content: '';
      display: block;
      border: 1px solid;
      top: 50%;
      left: 50%;
      width: 48px;
      height: 24px;
      transform: translate(-50%, -50%);

      @apply absolute rounded-[100px] border-[#909090];
    }

    &.creator::before {
      @apply border-orange-10;
    }
  }

  .avatar {
    @apply relative;

    &.creator::before {
      content: '';
      display: block;
      border: 1px solid;
      top: 50%;
      left: 50%;
      width: 36px;
      height: 36px;
      transform: translate(-50%, -50%);

      @apply absolute rounded-full border-orange-10;
    }
  }
}

.header-menu-dropdown, .header-navbar-dropdown {
  .icon {
    @apply flex pl-8 py-1.5 bg-no-repeat text-14px rounded-8px hover:bg-grey-3 active:bg-grey-3;

    background-position: 0.4rem center;
    background-size: 1.6rem 1.6rem;
  }

  .my-profile {
    background-image: url('~images/icons/my_profile.svg');
  }

  .my-saved-posts {
    background-image: url('~images/icons/my_saved_posts.svg');
  }

  .my-coupons {
    background-image: url('~images/icons/my_coupons.svg');
  }

  .my-library {
    background-image: url('~images/library.svg');
  }

  .my-following {
    background-image: url('~images/my_following.svg');
  }

  .account-my-model {
    background-size: 20px 20px;
    background-image: url('~images/model.svg');
  }

  .account-pv {
    background-size: 20px 20px;
    background-image: url('~images/home/phrozen_verified_white.svg');
  }

  .account-library {
    background-size: 20px 20px;
    background-image: url('~images/library.svg');
  }

  .account-payout {
    background-size: 20px 20px;
    background-image: url('~images/payout.svg');
  }
  .account-analytics {
    background-size: 20px 20px;
    background-image: url('~images/analytics.svg');
  }

  .account-edit_profile {
    background-size: 20px 20px;
    background-image: url('~images/edit_profile.svg');
  }

  .account-setting {
    background-size: 20px 20px;
    background-image: url('~images/icons/account_setting.svg');
  }

  .sign-out {
    background-size: 20px 20px;
    background-image: url('~images/icons/sign_out.svg');
  }

  .nav-explore {
    background-size: 24px 24px;
    background-image: url('~images/icons/nav-explore.svg');
    background-position: 4px center;
  }

  .nav-3dmodels {
    background-size: 20px 20px;
    background-image: url('~images/icons/nav-3dmodels.svg');
  }

  .nav-campaign {
    background-size: 20px 20px;
    background-image: url('~images/icons/nav-campaign.svg');
  }

  .nav-faq {
    background-image: url('~images/icons/nav-faq.svg');
  }

  .nav-discord {
    background-image: url('~images/icons/nav-discord.svg');
  }
}

.header-navbar {
  .menu-icon {
    background-position: center center;
    background-image: url('~images/icons/nav-menu-close.svg');

    @apply bg-no-repeat
  }

  &.close {
    .menu-icon {
      background-image: url('~images/icons/nav-menu-open.svg');
    }
  }
}

.notify-wrap {
  height: var(--mobile-modal-max-height);
    @media (min-width: 705px) {
   height: 584px;
  }

  .readall {
    cursor: pointer;
  }
}

.notify-area {
  height: calc(100% - 5.5rem);

  @apply smd:h-full;

  .active {
    background-color: rgba(87, 93, 102, 1);
    color: white;
    span {
      background-color: #E94C2B;
    }
  }
}
.notification-item {
  .notification-link {
    @apply w-full p-1 rounded-8px hover:bg-grey-3 active:bg-grey-3 items-center;

    img {
      @apply w-full h-full mr-1;
    }
    
    .followOrUnfollow {
      .unfollow-btn, .follow-btn {
        img {
          display: none;
        }
      }
    }
  }
}