@use "variables" as *;

// tailwindcss suggest position for purge css
@use "components";
@use "pages";

@use "swiper/scss";
@use "node_modules/select2/dist/css/select2.css";
@use "node_modules/@algolia/autocomplete-theme-classic/dist/theme.min.css";
@use "node_modules/image-compare-viewer/dist/image-compare-viewer.min.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  @include variables;
}

// Sets mobile font size dynamically based on viewport width, scaling from a base of 16px. 
@media (max-width: 560px) {
  html { font-size: var(--mobile-font-size); }
}

// Remove number input arrow
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// Remove number input arrow
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// hide scrollbar
html {
  overflow: -moz-hidden-unscrollable;
  height: 100%;
  scroll-padding-top: 77px;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  height: 100%;
  width: calc(100vw + 18px);
  overflow: auto;
}

.paypal_gw_btn{
  background-color: #0070ba;
}

.stripe_gw_btn{
  background-color: #625afa;
}

.select2 .selection{
  .select2-selection{
    background-color: transparent;
    li{
      @apply bg-gray-30
    }
  }
}

.select2-container {
  &.select2-container--focus .selection .select2-selection{
    border-color: #aaa;
  }
  .select2-results{
    ul{
      @apply text-gray-30;
    }
  }
}

// 112/07/28 refactor
.w-container {
  @apply px-4 sm:px-6 smd:px-8;

  max-width: 100%;
  height: 100%;
  @media (min-width: 1920px) {
    @apply mx-auto;

    width: 1920px;
  }
}

.w-account-container {
  @apply mx-auto;

  max-width: 1120px;
}

@media (max-width: 779px) {
  .line-clamp[data-content-ellipsis] {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // for safari
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.content-top-spacing {
  margin-top: calc(4rem + (var(--announcement-height) * var(--announcement-visible) * 1px)) !important;
}

.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}