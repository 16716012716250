.edit_post {
  &#post-file-group-form {
    .avaliable-formats:after {
      // display: block;
      // content: 'Avaliable formats: ' attr(data-display) '';
    }
  }
}


#post-type-switch {
  + label > .switch-btn {
    @apply bg-grey-3 pr-[20px] pl-1;

    span:before {
      @apply text-grey-3;

      content: 'OFF'
    }
  }

  &:checked {
    + label > .switch-btn {
      @apply bg-primary pl-[20px] pr-1;

      span:before {
        @apply text-primary;

        content: 'ON'
      }
    }
  }
}