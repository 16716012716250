#profile_libraries {
  .post_variant {
    .variant-more {
      .more-content {
        @apply hidden rounded-8px overflow-hidden top-full -right-2 z-1;
      }

      .icon.more-icon {
        @apply inline-block w-6 h-6 hover:bg-grey-1 rounded-full;

        background-image: url('~images/icons/More.svg');
      }

      .icon.report-icon {
        @apply inline-block w-6 h-6;

        background-image: url('~images/icons/flag_white.svg');
      }

      button {
        @apply px-4 py-2 bg-grey-2 text-white text-14px font-medium hover:bg-grey-1;
      }

      input:checked {
        ~ label .icon {
          @apply bg-grey-1;
        }
        ~ .more-content {
          @apply block;
        }
      }
    }

    a {
      .review-block {
        @apply absolute top-0 left-0 w-full h-full hidden;

        background: rgba(0, 0, 0, 0.6)
      }
      &:hover {
        .review-block {
          @apply md:flex flex-col items-center gap-y-4 justify-end pb-8;
        }
      }
    }

    .stars {
      .icon {
        @apply inline-block w-5 h-5 mr-1;

        content: '';
        background-image: url('~images/icons/icon_star_empty.svg');

        &.star-hover {
          background-image: url('~images/icons/icon_star.svg');
        }
      }
    }
  }
}