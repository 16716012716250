#post-comments {
  .cancel-btn {
    @apply hover:text-grey-3;
  }

  .comment-content, form {
    .content-count {
      @apply absolute bottom-1.5 right-2 text-xs text-grey-5;
    }
    .image {
      @apply w-[72px] h-[72px] bg-cover bg-center;
    }
    textarea {
      @apply h-[104px] md:h-[52px] p-2 focus:outline-none text-14px text-white bg-grey-7 border-[#8B8B8B];
    }
    .content a {
      @apply btn-secondary py-1 px-2 rounded-full;
    }
  }

  .image-icon {
    background-image: url('~images/camera.svg');
  }
  .delete-icon {
    @apply bg-center bg-cover;
    background-image: url('~images/dropzone-remove.svg');
  }

  .comment-item {
    .grid-1fr-auto {
      @apply grid-cols-[1fr_auto];
    }

    .grid-1fr-auto-auto {
      @apply grid-cols-[1fr_auto] sm:grid-cols-[1fr_auto_auto];

      > .created_at {
        @apply col-span-2 sm:col-span-1;
      }
    }

    .grid-auto-1fr {
      @apply grid-cols-[auto_1fr];
    }

    .post_likes {
      .icon {
        @apply inline-block bg-white;

        content: '';
        mask: url('~images/icons/thumbs-up.svg') no-repeat center / contain;
        -webkit-mask: url('~images/icons/thumbs-up.svg') no-repeat center / contain;

        &:hover {
          @apply bg-grey-5;
        }

        &.selected {
          @apply bg-primary;

          &:hover {
            @apply hover:bg-orange-10;
          }
        }

        &.unlike {
          transform: rotate(180deg)
        }
      }
    }

    .comment_replies {
      label {
        @apply mt-2 mb-2;
        .arrow-icon {
          @apply inline-block bg-primary;

          content: '';
          mask: url('~images/icons/profile-menu.svg') no-repeat center / contain;
          -webkit-mask: url('~images/icons/profile-menu.svg') no-repeat center / contain;
        }
      }

      .comment_replies_content {
        height: 0;
        overflow: hidden;
      }

      input[type="checkbox"]:checked {
        ~ label {
          @apply mb-0;

          .icon{
            transform: rotate(180deg)
          }
        }

        ~ .comment_replies_content {
          height: auto;
        }
      }
    }
  }

  .review-rating {
    .icon {
      @apply inline-block w-5 h-5 mr-1;

      content: '';
      background-image: url('~images/icons/icon_star_empty.svg');

      &.star-hover {
        background-image: url('~images/icons/icon_star_hover.svg');
      }

      &.star {
        background-image: url('~images/icons/icon_star.svg');
      }
    }
  }

  .comment-header {
    @apply flex mb-4 justify-between items-center gap-x-4;

    select + .default-input-klass { 
      @apply w-full sm:w-[152px] mb-0;
  
      .ts-dropdown-content {
        max-height: 300px;
      }

      .option {
        &:not(.selected):hover {
          @apply text-white;
        }

        &:hover {
          @apply bg-grey-1;
        }
      }
    }

    .comment-order-type {
      @apply flex items-center gap-x-2;

      .icon {
        @apply inline-block w-5 h-5;
        content: '';
        background-image: url('~images/icons/sort.svg');
      }

      label {
        @apply text-16px font-medium cursor-pointer leading-[20px];
      }

      input:checked + label {
        @apply text-primary;
      }
    }
  }

  .review-hint {
    @apply text-16px text-warning h-[188px] flex-col ;

    .icon {
      @apply w-8 h-8;

      background-image: url('~images/icons/Chat.svg');
    }
  }

  .nav {
    @apply px-4 py-4 text-16px md:text-18px font-medium;
  }

  .comment-more{
    .more-content {
      @apply hidden rounded-8px overflow-hidden top-[32px];
    }

    .icon.more-icon {
      @apply inline-block w-6 h-6 hover:bg-grey-1 rounded-full;

      background-image: url('~images/icons/More.svg');
    }

    .icon.edit-icon {
      @apply inline-block w-6 h-6;

      background-image: url('~images/icons/NotePencil.svg');
    }
    .icon.delete-icon {
      @apply inline-block w-6 h-6;

      background-image: url('~images/icons/trash.svg');
    }

    .icon.report-icon {
      @apply inline-block w-6 h-6;

      background-image: url('~images/icons/flag.svg');
    }

    button {
      @apply px-4 py-2 bg-grey-2 text-grey-5 text-14px font-medium hover:bg-grey-1;
    }

    input:checked {
      ~ label .icon {
        @apply bg-grey-1;
      }
      ~ .more-content {
        @apply block;
      }
    }
  }

  select + .default-input-klass {
    @apply w-[152px] mb-4;

    .ts-dropdown-content {
      max-height: 300px;
    }
  }
}