#home-slider {
  @apply flex flex-col xl:flex-row gap-4 md:gap-6 lg:gap-4 xl:gap-6;

  .home-banner {
    @apply rounded-xl overflow-hidden w-full;

    @media (min-width: 1280px) {
      width: calc( 100% * 630 / 1216 );
    }

    @media (min-width: 1536px) {
      width: calc( 100% * 822 / 1472 );
    }

    @media (min-width: 1920px) {
      width: calc( 100% * 1040 / 1856 );
    }

    .swiper-slide {
      @apply bg-cover bg-right rounded-xl;
    }

    .swiper-button-prev, .swiper-button-next {
      @apply w-8 h-8 rounded-full border-2 border-white p-1 cursor-pointer opacity-60 hover:opacity-100;
      @apply hidden md:block absolute z-1 top-auto bottom-2 lg:bottom-4;
    }

    .swiper-button-prev {
      @apply left-auto right-[56px] lg:right-[72px];
    }

    .swiper-button-next {
      @apply right-2 lg:right-6;
    }

    .swiper-pagination {
      @apply z-10 w-max absolute right-1/2 bottom-2 md:bottom-4 lg:bottom-6 translate-x-1/2 transition-opacity ease-in-out duration-200;

      span {
        @apply w-5 h-1 mx-1 inline-block cursor-pointer;
        @apply rounded-full bg-[#FFFBFB] opacity-40 hover:opacity-80;
      }

      .swiper-pagination-bullet-active {
        @apply opacity-80;
      }
    }

    .btn-gradient {
      background: linear-gradient(99deg, #FF694A 15.91%, #9C07E3 100%);

      &:hover {
        background: linear-gradient(99deg, #DE4423 29.23%, #6D00A0 100%);
      }
    }
  }
  .home-featured-post {
    @apply flex-1 grid xs:grid-cols-2 smd:grid-cols-3 lg:grid-cols-6 xl:grid-cols-3 gap-4 md:gap-6 lg:gap-4 xl:gap-6;

    a {
      @apply block w-full aspect-square bg-cover bg-center rounded-8px;
    }

    .tag {
      @apply absolute px-2 text-12px font-medium w-fit rounded-tl-8px rounded-br-8px;
    }

    .new-tag {
      color: #736700;
      background-color: #FFEA29;
    }

    .free-tag {
      color: #19791D;
      background-color: #2CFF67;
    }

    .post {
      @apply h-full rounded-8px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 80%, rgba(0, 0, 0, 0.8) 100%);
    }
  }
  .home-ranking-post { 
    @apply relative h-full w-full overflow-hidden;

    .home-ranking-tabs .home-ranking-tabs-button {
      @apply border rounded-[100px] py-1 px-2;
      &.active {
        @apply text-white border-[#FF694A];
      }
      &:not(.active) {
        @apply text-[#8B8B8B] border-transparent;
        &:hover {
          @apply text-[#CACACA];
        }
      }
    }

    .svg-color-4d5158 {
      filter: invert(34%) sepia(11%) saturate(206%) hue-rotate(177deg) brightness(50%) contrast(89%);
    }

    .swiper {
      @apply w-full h-full overflow-hidden;
    }
  
    .swiper-wrapper {
      @apply items-center flex-nowrap py-8 px-4;
    }
  
    .swiper-slide {
      @apply h-auto w-auto flex-shrink-0 transition-transform duration-300;

      &:hover {
        .swiper-slide-shadow-left,
        .swiper-slide-shadow-right {
          @apply scale-x-105 duration-300;
        }
        a.post-link {
          @apply scale-105 duration-300;
        }
      }
    }
  
    a.post-link {
      @apply block bg-cover bg-center rounded-8px;
      @apply w-[221px] h-[308px];
      @apply md:w-[258px] md:h-[360px];
      @apply lg:w-[207px] lg:h-[289px];
      @apply 2xl:w-[288px] 2xl:h-[402px];
    }
  
    .rank-tag {
      @apply absolute -top-[46px] left-1/2 -translate-x-1/2 z-10;
    }
  
    .post {
      @apply h-full rounded-8px mt-4;
      background: linear-gradient(180deg, rgba(36, 39, 44, 0) 0%, #24272C 100%);
      box-shadow: 0px 10px 20px 0px #00000080;
    }
  }
}

#home-popular-tags {
  .swiper-button-prev, .swiper-button-next {
    @apply rounded-full border-2 border-white p-1 hover:bg-grey-3 cursor-pointer;
  }

  .home-tag-slider-mask {
    pointer-events: none;
    z-index: 1;

    &:after {
      content: '';
      width: 32px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 40px;
      background: linear-gradient(90deg, rgba(36, 39, 44, 0), rgba(36, 39, 44, 1));
    }
  }

  .home-popular-tags {
    width: calc(100% - 80px);

    .tags {
      @apply inline-flex relative py-[6px] px-6 rounded-full text-14px;

      overflow: hidden;

      &:before {
        @apply absolute w-full h-full top-0 left-0 rounded-full;

        content: '';
        background: var(--tag-color);
        opacity: .1;
        z-index: -1;
      }

      &:hover {
        &:before {
          opacity: .25;
        }
      }
    }
  }
}

.home-post-wrapper {
  min-height: 300px;

  .skeleton-loader {
    opacity: 0;
  }
}