// Deprecated
.select-box {
  @apply relative cursor-pointer;

  input[type=radio] {
    @apply absolute -z-1 opacity-0;
  }

  .input {
    @apply p-2 hidden relative;

    &:after {
      @apply absolute;

      content: '';
      top: 14px;
      right: 18px;
      height: 10px;
      width: 10px;
      border: 2px solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transform-origin: 75% 75%;
    }
  }

  :checked {
    + .input {
      @apply flex items-center;
    }
  }

  .options {
    @apply absolute z-20 hidden pointer-events-none;
    @apply rounded-8px border-grey-2 bg-grey-2 text-16px text-white font-normal overflow-hidden;

    box-sizing: content-box;
    box-shadow: 0px 5px 10px 1px rgba(0,0,0,.25);

    .option {
      @apply flex items-center cursor-pointer pointer-events-auto;

      padding: 5px 8px;

      &:hover{
        @apply text-orange-20;
      }
    }
  }

  &:focus, &:hover{
    .input {
      &:after {
        transform: rotate(225deg);
      }
    }
    .options {
      @apply block pointer-events-auto;
    }
  }
}

// New
.dropdown {
  @apply w-full p-2 text-base bg-grey-2 border border-white rounded-md appearance-none;
  
  background-image: url('~images/arrow-down.svg'); /* 添加自定義的下拉箭頭圖片 */
  background-repeat: no-repeat;
  background-position: right 20px center; /* 箭頭位置在右邊，水平方向10px偏移 */
  background-size: 15px 15px; /* 箭頭圖片的大小 */

  &:focus-visible{
    outline: none;
  }
}