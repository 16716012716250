.post-widgets {
  @apply flex flex-nowrap items-center overflow-hidden;

  .likes,
  .likes.focus,
  .save,
  .save.focus,
  .shared,
  .shared.focus,
  .edit-post,
  .destroy-post,
  .view-count{
    @apply bg-no-repeat bg-center bg-contain;
  }

  .likes {
    background-image: url('~images/icons/likes.svg');

    &:hover {
      background-image: url('~images/icons/likes-hover.svg');
    }

    &.focus {
      background-image: url('~images/icons/likes-focus.svg');
    }
  }

  .save {
    background-image: url('~images/icons/save.svg');

    &:hover {
      background-image: url('~images/icons/save-hover.svg');
    }

    &.focus {
      background-image: url('~images/icons/save-focus.svg');
    }
  }

  .shared {
    background-image: url('~images/icons/shared.svg');

    &:hover {
      background-image: url('~images/icons/shared-hover.svg');
    }

    &:active {
      background-image: url('~images/icons/shared-focus.svg');
    }
  }

  .view-count {
    background-image: url('~images/icons/view-count.svg');
  }

  .edit-post {
    background-image: url('~images/icons/edit-post.svg');

    &:hover {
      background-image: url('~images/icons/edit-post-hover.svg');
    }

    &:active {
      background-image: url('~images/icons/edit-post-focus.svg')
    }
  }

  .destroy-post {
    background-image: url('~images/icons/destroy-post.svg');
  }
}
