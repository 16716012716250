#post-image-full-screen-slide {
  @apply z-30 flex flex-col;

  background: rgba(36, 39, 44, .9);

  .close-icon {
    @apply inline-block w-6 h-6 top-8 right-8 z-30;

    background-image: url('~images/close.svg');
  }

  .swiper-container {
    @apply pt-4 text-center;

    .swiper-wrapper .swiper-zoom-container img{
      @apply max-h-full max-w-full sm:max-w-[90%] md:max-w-[690px];
      @apply bg-no-repeat bg-center bg-contain cursor-zoom-in;

      &.swiper-zoom-container--zoomed {
        @apply cursor-zoom-out;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      @apply hidden md:block absolute w-[28px] h-[28px] p-[4px] sm:w-10 sm:h-10 sm:p-2;
      @apply border border-white border-2 rounded-full z-30 cursor-pointer;

      top: 100%;
      transform: translateY(-2px);

      @media (min-width: 780px) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .swiper-button-prev{
      @apply left-6 md:left-8;
    }

    .swiper-button-next{
      @apply right-6 md:right-8;
    }

    .swiper-btn {
      @apply w-[16px] h-[16px] sm:w-[20px] sm:h-[20px];
      background-image: url('~images/scroll-top.svg');
    }
  }

  .swiper-pagy {
    @apply w-8 h-8;

    background-image: url('~images/images_pagy.svg');
  }

  .swiper-pagination {
    @apply absolute top-8 left-8 z-10;

    background: rgba(255, 255, 255, 0.20);
  }

  .swiper-container-thumb {
    @apply p-4;

    .swiper-wrapper {
      img{
        @apply h-[120px] md:h-[152px];
      }
    }

    .swiper-slide{
      position: relative;

      &:after {
        @apply absolute block w-full h-full top-0 cursor-pointer;

        content: '';
        background: #000;
        opacity: 0.7;
      }
    }

    .swiper-slide-thumb-active {
      &:after{
        opacity: 0;
      }
    }
  }
}