.individual-posts-container {
	.checkbox-dropdown {
		display: none;

		+ label {
		  @apply cursor-pointer relative;

      &:after {
        @apply w-3 h-3 absolute right-4;
        @apply transition-all duration-300;
        content: '';
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
      }
		}
		~ .dropdown-content {
      max-height: 0;
      overflow: hidden;
		}

		&:checked {
      + label {
        &:after {
          @apply top-[6px];
          transform: rotate(135deg);
        }
      }

      ~ .dropdown-content {
        max-height: 200px;
      }
		}
	}

  .individual-post-collapse-btn {
    @apply ml-3 sm:ml-[3px] lg:ml-3
  }
  .revenues-hidden {
    display: none !important;
  }
    
  .individual-post-revenues {
    background-color: var(--color-grey-3);
    @media (max-width: 560px) {
      width: 42rem;
    }
  }

  .icon {
    &.analytics-icon {
      background-image: url('~images/analytics.svg');
    }
  }
}
  