// 覆蓋 @algolia/autocomplete-theme-classic 的樣式

// input 
.aa-Form {
  --aa-input-background-color-rgb: 47, 50, 55;
  border-color: transparent !important;
  border-radius: 3rem !important;
  height: 40px;
  &:focus-within {
    border-color: #2bdee9 !important;
  }
  .aa-Input {
    color: #fff !important;
    &::placeholder {
      @apply text-[14px] smd:text-base; 
      color: #C6C9CC ;
    }
  }
  
  .aa-Label svg, .aa-LoadingIndicator svg {
    color: #C6C9CC  !important;
  }

  .aa-SubmitButton {
    margin-left: 0.5rem;
  }
}

// 下拉選單
.aa-Panel {
  --aa-background-color-rgb: 47, 50, 55;
  --aa-scrollbar-track-background-color-rgb: 47, 50, 55;
  --aa-text-color-rgb: 255, 255, 255;
  --aa-panel-max-height: 700px;
  --aa-selected-color-alpha: 0;

  color: #fff !important;
  border-radius: 1rem !important;
  z-index: 60;
  box-shadow: 0px 4px 4px 0px #00000040;

  @apply top-0 smd:mt-20 lg:mt-2 #{!important};
  @screen smd {
    top: calc(52px + (72px * var(--announcement-visible))) #{!important};
  }

  .aa-Panel--scrollable {
    @apply scrollbar-gutter-auto;

    padding: 12px 0 0;
    scrollbar-color: unset;
    scrollbar-width: unset;

  }
  .aa-Item {
    padding: 0.25rem 1rem;
    &:hover {
      background-color: var(--color-grey-2);
    }

    .aa-ItemContentTitle {
      display: flex;
      align-items: center;

      .creator-tag {
        @apply text-xs bg-orange-10 text-white ml-2 px-2 py-1 rounded-2xl;
        font-size: 11px;
      }
    }
  }
  
  .aa-tags {
    margin-top: 1rem;
    .aa-tags-container {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1rem 1.5rem;
      &:last-child {
        padding-bottom: 0.5rem;
      }
      .aa-tags-title {
        display: block;
        width: 100%;
        color: var(--color-gray-20);
        margin-bottom: 0.5rem;
        font-weight: 500;
        font-size: 14px;
      }
      .aa-tags-items {
        display: flex;
        flex-wrap: wrap;
      }
      .aa-tags-item {
        display: flex;
        border-radius: 1rem;
        background-color: var(--color-gray-50);
        color: #fff;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 14px;

        &:hover {
          background-color: var(--color-grey-2);
        }
      }
    }
  }

  .aa-SourceNoResults {
    padding: 0;
  }
}
// mobile search input
.aa-DetachedSearchButton {
  --aa-input-background-color-rgb: 47, 50, 55;
  border-color: transparent !important;
  border-radius: 3rem !important;
  background-color: #2F3237 !important;
  
  .aa-DetachedSearchButtonIcon {
    color: var(--color-gray-20);
  }
  .aa-DetachedSearchButtonQuery {
    color: #fff ;
  }
}
// mobile dropdown
.aa-DetachedOverlay {
  --aa-background-color-rgb: 24, 28, 31;

  .aa-DetachedCancelButton {
    color: #fff;
  }
}

.aa-Panel--scrollable::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-50);
}