.pagy-nav {
  &.pagination {
    @apply flex items-center justify-center my-4;
  }

  .page {
    @apply mx-2 w-5 py-0.5 border-b-2 flex justify-center items-center md:text-base text-xs;

    border-color: rgba(0,0,0,0);
  }

  .current {
    border-color: #E94C2B;
  }

  .prev, .next {
    @apply w-6 p-0 border-0;
    a {
      @apply rounded-full inline-flex justify-center items-center;

      background: #E94C2B;
      padding: 3px;
      &:hover {
        background: #F26E52;
      };
      &:active {
        background: #C7391B;
      }
    }

    img {
      @apply w-4.5 h-4.5;
    }
  }

  .prev {
    @apply mr-9;
  }

  .next {
    @apply ml-9;
  }
}
