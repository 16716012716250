#profile_inbox {
  @apply h-full py-4 sm:py-6 md:py-10;

  #inbox-container {
    @apply grid grid-cols-[1fr] sm:grid-cols-[230px_1fr] smd:grid-cols-[272px_1fr] lg:grid-cols-[305px_1fr];
  }

  input[name='coversation_list_opener'] {
    @apply hidden;

    + #inbox-container {
      #coversaction-container {
        @apply flex;
      }

      #message-container {
        @apply hidden sm:flex;
      }
    }

    &:checked + #inbox-container {
      #coversaction-container {
        @apply hidden sm:flex;
      }
      #message-container {
        @apply flex;
      }
    }
  }

  #coversaction-container {
    .coversaction {
      @apply relative p-1 pr-2 border border-grey-2 hover:border-[#585D66] hover:bg-[#585D66] cursor-pointer rounded-8px;

      &.current {
        @apply border-primary bg-[#52413E];
      }
    }
    .coversaction-alert {
      @apply block absolute top-[2px] right-[2px] bg-primary rounded-full;

      width: 10px;
      height: 10px;
      content: '';
    }
  }
  #message-container {
    .message {
      .message-content {
        @apply py-1 px-2 inline-block max-w-[300px] border rounded-8px;

        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.25);

        a {
          @apply break-all decoration-1;

          text-decoration: underline;
        }
      }

      &.sent {
        @apply text-right;

        .message-content{
          @apply rounded-br-none border-orange-10 bg-[#91493B];
        }
      }

      &.received {
        @apply text-left;

        .message-content{
          @apply rounded-bl-none border-grey-3 bg-[#474B51];
        }
      }
    }

    .input-container {
      @apply flex p-2 pr-1.5 w-full items-end bg-[#2F3237] border border-[#8B8B8B] rounded-8px gap-x-4;

      textarea {
        @apply flex-1 text-14px leading-7 text-white outline-none;

        background: none;
        resize: none;
      }

      button {
        @apply text-14px btn-sm btn-secondary;

        border-radius: 20px;
      }
    }
  }
}