@use "node_modules/dropzone/src/dropzone";
.dropzone {
  padding: 36px 8px !important;
  border-radius: 0.5rem;

  .dz-preview {
    @apply relative min-h-[80px] bg-grey-2 rounded-8px;

    &.dz-image-preview, &.dz-file-preview {
      @apply bg-grey-2 m-2;

      .dz-image {
        @apply bg-grey-2 rounded-8px;

        background: none;
      }
    }

    .dz-details {
      @apply p-0;

      .dz-size {
        @apply mb-0 mt-4 text-12px;
      }
    }

    &.dz-file-preview {
      .dz-details {
        @apply text-white;

        .dz-size {
          span {
            background: none;
          }
        }

        .dz-filename {
          span {
            background: none;
          }
          &:hover span{
            @apply bg-grey-2;
          }
        }
      }
    }

    .dz-image {
      @apply w-[80px] h-[80px] rounded-8px;
    }

    .dz-progress {
      @apply bg-grey-7;

      left: 50px;
      top: 70px;
      height: 5px;
      width: 60px;

      .dz-upload {
        @apply bg-white rounded-8px;

        background: none;
      }
    }

    .dz-remove {
      @apply absolute -top-2 -right-2;
      z-index: 30;

      img {
        @apply cursor-pointer;
      }
    }
  }

  &[data-disabled='true'] {
    @apply border-grey-1;
    cursor: not-allowed;

    .dz-remove {
      @apply hidden;
    }
  }

  .sortable-ghost .dz-image{
    @apply border border-orange-20;
  }
}

.dz-drag-hover {
  background: linear-gradient(0deg, rgba(82, 154, 255, 0.2), rgba(82, 154, 255, 0.2));
  border: 1px dashed #529AFF !important;
}

div[data-dropzone='postImage'] {
  .dz-default + .dz-preview {
    @apply relative;
    border: 3px solid #2BDEE9;
    &::before {
      @apply absolute top-[-25px] left-2.5 w-16 bg-[#2BDEE9] rounded-lg text-center text-[10px] px-2 text-black;
      content: 'Thumbnail';
    }
  }
}

.file-group {
  .dz-default + .dz-preview {
    border: none;
    &::before {
      content: none;
    }
  }
}