.join-us {
  @apply mx-[-16px] sm:mx-[-20px] smd:mx-[-32px];
  width: calc(100% + 32px);

	@media (min-width: 560px) {
    width: calc(100% + 32px);
	  min-height: 400px;
	}
	@media (min-width: 705px) {
    width: calc(100% + 64px);
	}

  @media (min-width: 2160px) {
    margin: 0 -152px;
	}

  .join-us-bg {
    position: relative;
    z-index: 1;
  }
  
	.join-us-bg::before {
	  content: '';
	  position: absolute;
	  inset: 0;
    background-image: var(--bg-image);
	  background-size: cover;
	  z-index: -1;
    background-position: center;
    background-repeat: no-repeat;

    background-image: var(--bg-image-mobile);
    @media (min-width: 560px) {
      background-image: var(--bg-image-tablet);
    }
    @media (min-width: 1280px) {
      background-image: var(--bg-image-large);
    }
	}

  .join-us-top-title {
    opacity: 0.9;

    .anime1 {
      display: inline-block;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      animation: byBottom 0.85s ease both;
      -webkit-animation: byBottom 0.85s ease both;
      -moz-animation: byBottom 0.85s ease both;
      -o-animation: byBottom 0.85s ease both;
    }
    .anime2 {
      display: inline-block;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      animation: byBottom 0.95s ease both;
      -webkit-animation: byBottom 0.95s ease both;
      -moz-animation: byBottom 0.95s ease both;
      -o-animation: byBottom 0.95s ease both;
    }
  

  }
  .join-us-top-content {
    opacity: 0.8;

    .best-designers-belong {
      display: inline-block;
      animation: colorCycle 5s linear infinite;
    }
  }
}

#session2 {
  .indent-48 {
    @media (min-width: 560px) {
      text-indent: 110px;
    }
    @media (min-width: 1024px) {
      text-indent: 180px;
    }
  }
  .box {
    @apply flex flex-col max-w-[332px] sm:max-w-[656px] w-[556px] 2xl:w-[656px] rounded-[40px] px-6 sm:px-12;
  
    border: 1px solid #FFFFFF33;
    background-color: #8080801A;
    backdrop-filter: blur(10px)
  }

  .coming-soon-text {
    color: transparent;
    background: #fff;
    text-shadow: 
      5px -4px 4px rgba(0, 0, 0, 0.5);
    background-clip: text;
    -webkit-background-clip: text;
  }
}

.join-us-faq-wrapper {
	/* 隱藏選擇框 */
	.dropdown-toggle {
	  display: none;
	}
	
	/* 設置下拉內容樣式 */
	.content {
	  max-height: 0;
	  transition: max-height 0.35s ease-in-out;
	  border-radius: 5px;
	  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}
	.button img {
	  transition: transform 0.3s ease-in-out;
	}
	
	/* 當選擇框被勾選時，顯示下拉內容 */
	.dropdown-toggle:checked + .button + .content {
	  max-height: 500px; /* 設置一個足夠大的值來模擬展開效果 */
	}
	
	.dropdown-toggle:checked + .button img {
	  transform: rotate(0.5turn);
	}
}

@-webkit-keyframes byBottom {
	0% {
	  transform: translateY(150%);
	}
	100% {
	  transform: translateY(0);
	}
}

@-moz-keyframes byBottom {
	0% {
	  transform: translateY(150%);
	}
	100% {
	  transform: translateY(0);
	}
}

@-o-keyframes byBottom {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes byBottom {
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0);
  }
}
  

@keyframes colorCycle {
  0% {
    color: #FF694A; 
  }
  20% {
    color: #98C8FF;
  }
  40% {
    color: #3FFF3F;
  }
  60% {
    color: #FFEF3F;
  }
  80% {
    color: #FF3FFC;
  }
  100% {
    color: #FF694A;
  }
}

