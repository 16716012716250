@mixin variables {
  --modal-min-width: 20.5rem;
  --post-modal-min-width: 48.875rem;
  --following-request-modal-min-width: 27rem;
  --mobile-font-size: #{calc(16px / 4.14px * 1vw)};  // Sets mobile font size dynamically based on viewport width, scaling from a base of 16px. 
  --mobile-modal-max-height: calc(100%);
  // --mobile-modal-max-height: calc(100% - 3.5rem); 當 手機版的mobile-toolbar存在時使用

  --color-primary: #E94C2B;
  --color-gray-10: #D1D1D1;
  --color-gray-15: #8F9193;
  --color-gray-20: #888D95;
  --color-gray-30: #575D65;
  --color-gray-31: #515458;
  --color-gray-32: #515559;
  --color-gray-33: #404449;
  --color-gray-35: #383C42;
  --color-gray-40: #363A3F;
  --color-gray-41: #35393D;
  --color-gray-43: #2F3439;
  --color-gray-45: #2F3237;
  --color-gray-46: #24272C;
  --color-gray-50: #181C1F;

  --color-orange-5: #FF8971;
  --color-orange-10: #FA6A4C;
  --color-orange-20: #E94C2B;
  --color-orange-30: #D43815;

  --color-grey-1: #575D66; // color/grey/40 text/text-disabled icon/icon-disabled Input/Input-border-disabled
  --color-grey-2: #3E434A; // color/grey/50 bg/bg-form Input/Input-fill-hover
  --color-grey-3: #6E747C; // button/button-secondary Input/Input-default
  --color-grey-4: #2F3237; // bg/bg-search
  --color-grey-5: #99A0A8; // color/grey/10 icon/icon-secondary text/text-secondary button/button-secondary-hover
  --color-grey-6: #E3E3E3;
  --color-grey-7: #24272C; // color/grey/70
  --color-grey-8: #CECECE;
  --color-grey-9: #3A3C40;
  --color-grey-10: #4B4B4B;

  --color-blue-10: #2BDEE9;
  --color-blue-20: #93F0FC;

  --color-post-state-all: #2F3237;
  --color-post-state-init: #99A0A8;
  --color-post-state-draft: #99A0A8;
  --color-post-state-pending: #FFB72F;
  --color-post-state-progress: #4F84EB;
  --color-post-state-submit-rejection: #FF7474;
  --color-post-state-submit-success: #29CB00;
  --color-post-state-published: #29CB00;
  --color-post-state-completed: #29CB00;

  --color-danger: #FF8989;
  --color-warning: #FFB72F;
  --color-warning-2: #FFCC6C;
  --color-warning-3: #FFD78A4D;

  --font-family: 'Roboto';

  --letter-spacing-small: 0.015625rem;

  --image-ratio-1x1-height: #{calc(100% * 1)};
  --image-ratio-3x2-height: #{calc(100% * 2 / 3)};

  --box-shadow-10: 0px 0px 0.625rem rgba(0, 0, 0, 0.5);
  --box-shadow-15: 0px 0px 0.9375rem rgba(0, 0, 0, 0.5);
  --box-shadow-20: 0px 0px 1.25rem rgba(0, 0, 0, 0.5);
  --box-shadow-27: 0px 0px 1.6875rem rgba(0, 0, 0, 0.5);

  --category-background-mask: linear-gradient(0deg, rgba(47, 50, 53, 0.7), rgba(47, 50, 53, 0.7));
  --category-image-height: #{calc(3 / 9 * 100%)};
  --posts-background-mask: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  --posts-gradient-mask: linear-gradient(0deg, rgba(19, 21, 23, 0.7), rgba(0, 0, 0, 0));
  --posts-footer-gradient: linear-gradient(0deg, #31363B 0%, rgba(49, 54, 59, 0) 56.75%);
  --newpost-background: linear-gradient(180deg, #383C42 0%, #2F3235 100%);
  --newpost-hover-background: linear-gradient(180deg, #515459 0%, #3B4045 100%);

  --avatar-drop-shadow: 0px 0.3125rem 1rem rgba(0, 0, 0, 0.5);
  --arrow-drop-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

  --announcement-visible: 0; // 1 indicates visible, 0 indicates hidden
  --announcement-height: 0;
}
