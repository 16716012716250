.image-block {
  @apply relative flex;

  &:not(.no-shadow) {
    @apply shadow-10px;
  }

  &:before {
    content: '';
    padding-top: var(--category-image-height);
    @apply float-left;
  }

  &.ratio-1x1 {
    &:before {
      padding-top: var(--image-ratio-1x1-height);
    }
  }

  &.ratio-3x2 {
    &:before {
      padding-top: var(--image-ratio-3x2-height);
    }
  }

  .image {
    @apply absolute rounded-7px w-full h-full object-cover;
  }

  .image-text {
    @apply absolute inset-0 z-10 flex justify-center items-center text-white
  }

  &:not(.no-mask){
    &:after {
      content: '';
      background: var(--category-background-mask);
      @apply absolute w-full h-full rounded-7px;
    }
  }
}

.dropdown {
  + * {
    @apply hidden;
  }

  &:checked + * {
    @apply block;
  }
}
