// 使用在 modal_component 
.modal-checkbox {
  @apply absolute z-50 dropdown -left-full opacity-0;
}

.modal-container {
  @apply fixed z-30 inset-0 w-screen;

  .modal-black-cover {
    @apply absolute w-full h-full bg-black opacity-70;
  }

  .modal-content-layout {
    @apply relative flex w-full h-full justify-center items-center;
  }

  .modal-content-container {
    @apply relative flex flex-col w-full md:w-auto md:max-w-2/3 lg:max-w-1/2 2xl:max-w-1/3 h-screen md:h-auto max-h-full md:max-h-screen-9/10 bg-grey-2 md:rounded-8px overflow-hidden;
    &.request-model {
      width: 364px !important;
      
      .focus-color:focus {
        outline: none ;
        border:1px solid  var(--color-blue-10);
      }
    }
  }

  .modal-close-btn {
    @apply absolute right-6 top-6 flex justify-center items-center w-7.5 md:w-10 h-7.5 md:h-10 cursor-pointer;
  }

  .modal-close-icon {
    @apply w-4 md:w-5 h-4 md:h-5 object-contain pointer-events-none;
  }

  .modal-content {
    @apply flex flex-col overflow-hidden;
  }
}

.alert-checkbox {
  @apply absolute z-50 dropdown -left-full opacity-0;
}

.alert-container {
  @apply fixed z-999 w-screen left-0 bottom-[144px] sm:bottom-auto sm:top-[112px];

  .alert-content-layout {
    @apply relative flex justify-center;
  }

  .alert-content-container {
    @apply border rounded-8px;
    max-width: 80%;

    &.success {
      border-color: #4AE324;
      background-color: #51A93BE5;

      &:hover {
        background-color: #51A93B;
      }
    }

    &.danger {
      border-color: #FF8989;
      background-color: #CC4C4CE5;

      &:hover {
        background-color: #CC4C4C;
      }
    }

    &.warning {
      @apply border-warning;

      background-color: #C88A15E5;

      &:hover {
        background-color: #C88A15;
      }
    }
  }
}

#phrozen-verified-disclaimer + .modal-container, #profile_phrozen_verified .modal-container {
  .modal-content-container {
    @apply md:min-w-[664px];

    .icon {
      background-image: url('~images/icons/checkbox_unchecked.svg');
    }

    input:checked + label .icon {
      background-image: url('~images/icons/checkbox_checked.svg');
    }
  }
}

.modal-container.vue-modal {
  .modal-content-container {
    @apply md:min-w-0 md:w-[364px] !important;
  }
}