.announcement {
  @apply flex flex-row items-center justify-center w-full h-auto px-6 sm:px-4 py-2 z-40 relative;
  background: linear-gradient(97.94deg, rgba(156, 7, 227, 0.25) 15.75%, rgba(255, 105, 74, 0.25) 84.85%);
  background-blend-mode: color-dodge;
  .announcement-container {
    @apply flex  items-center justify-center;

    .link-btn {
      @apply px-4 py-2 h-[36px] rounded-lg text-[14px] sm:ml-2 md:ml-4;
      flex: 0 1 auto;
      text-align: center;
      background: linear-gradient(98.93deg, #FF694A 15.91%, #9C07E3 100%);
      background-blend-mode: color-dodge;
      &:hover {
        background: linear-gradient(98.93deg, #DE4423 29.23%, #6D00A0 100%);
        background-blend-mode: color-dodge;
      }
    }
  }
  .image {
    flex: 0 0 40px
  }
  .content-container {
      @apply flex flex-col sm:flex-row sm:justify-center sm:items-center;
      h3 {
        @apply text-[14px] sm:mr-4 sm:ml-2 font-bold;
        /* 設定線性漸層為文字背景 */
        background: linear-gradient(134.56deg, #FF5F3E 25.75%, #D300E6 89.92%);
        /* 使用 background-clip 將背景應用於文字 */
        -webkit-background-clip: text;
        background-clip: text;
        /* 將文字顏色設為透明，以顯示背景 */
        color: transparent;
        /* 使漸層與其他背景混合 */
        background-blend-mode: lighten;
      }
      div {
        @apply text-[12px] sm:text-[14px];
      }
  }
  
  .close-btn {
    @apply absolute top-2 right-2 smd:top-1/2 smd:translate-y-[-50%] smd:right-8;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }
  
  .close-btn::before, .close-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 2px;
    background-color: #fff;
  }
  
  .close-btn::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .close-btn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}