.filter-option {
  @apply relative;
  input {
    @apply absolute w-full h-full z-10 opacity-0 cursor-pointer;
  }
  .checkbox {
    @apply block border relative border-gray-10 rounded-3px;
    content: "";
    height: 18px;
    width: 20px;

    &:after{
      @apply absolute transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
      content: "";
      height: 80%;
      width: 80%;
    }
  }

  input:checked ~ {
    .checkbox {
      &:after{
        background: var(--color-gray-10);
      }
    }
  }
}