.post-card {
  // background: var(--posts-footer-gradient);
}

.image-wrap {
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .post-data-container {
    @apply flex
  }
  .free-tag {
    color: #19791D;
    background-color: #2CFF67;
    border-radius: 4px;
    line-height: 16px;
  }
  
  .pv-tag {
    background-color: #E60044;
    border-radius: 4px;
    line-height: 16px;
  }

  .post-view-count {
    font-size: 12px;
    color: #EAEAEA;
    text-shadow: 0px 4px 4px rgba(0,0,0,.05);

    .icon {
      @apply bg-no-repeat bg-center bg-contain;
      background-image: url('~images/icons/view-count.svg');
      filter: invert(10%) sepia(10%) saturate(0%) hue-rotate(180deg) brightness(95%) contrast(87%) drop-shadow(5px 5px 5px rgba(0,0,0,.15));
    }
  }
  .post-likes-count {
    font-size: 12px;
    color: #EAEAEA;
    .icon {
      @apply bg-no-repeat bg-center bg-contain;
      background-image: url('~images/icons/likes.svg');
    }
  }
  .post-downloads-count {
    font-size: 12px;
    color: #EAEAEA;
    .icon {
      @apply bg-no-repeat bg-center bg-contain;
      background-image: url('~images/download.svg');
    }
  }
}

.gradient-mask{
  @apply absolute bottom-0 left-0 w-full h-7 hidden rounded-b-8px;

  backdrop-filter: blur(1.5px);
  // @apply hidden ;
  // background: var(--posts-gradient-mask);
}

.post-card-mask {
  &.block{
    .gradient-mask{
      display: flex;
    }
    + .mask-btns{
      opacity: 1;
    }
  }
}

.post-title{
  @apply text-18px;
  font-family: Roboto;
  font-style: normal;
}

.model-search-form {
  .post-title::-webkit-search-cancel-button {
    filter: invert(50%) sepia(20%) saturate(200%) hue-rotate(180deg) brightness(70%) contrast(90%);
  }
  .post-title:hover::-webkit-search-cancel-button {
    filter: invert(50%) sepia(20%) saturate(200%) hue-rotate(180deg) brightness(50%) contrast(90%);
    cursor: pointer;
  }
}

// useless
.post-lists {
  &-details {
    display: none;
  }

  &-likes {
    @apply pl-7 bg-no-repeat bg-left;
  }

  &-likes {
    background-image: url('~images/icons/likes.svg');
  }

  &:hover &-details {
    @screen md {
      display: block;
      background: var(--posts-background-mask);
    }
  }
}
