@mixin default-button-klass {
  @apply px-4 py-1 smd:py-2 rounded-8px text-white text-16px text-center cursor-pointer;

  line-height: inherit;
}

.btn {
  @include default-button-klass;

  &[disabled], &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none; 
  }
}

.btn-sm {
  @apply btn;
  @apply py-1 text-14px;

  line-height: 20px;
}

.btn-primary {
  @apply bg-orange-20;

  &:not([disabled]), &:not(.disabled) {
    @apply hover:bg-orange-10;
  }
}

.btn-secondary {
  @apply bg-grey-3;

  &:not([disabled]), &:not(.disabled) {
    @apply hover:bg-grey-5;
  }
}

.btn-outline-primary {
  @apply text-white border-primary bg-[#422621] border;

  .icon {
    @apply bg-primary;
  }
}

.btn-outline-blue {
  @apply text-white border-[#2BDEE9] bg-[#1A2F33] border;

  .icon {
    @apply bg-[#2BDEE9];
  }
}

.btn-outline-white {
  @apply text-white border border-white;

  &:not([disabled]):not(.disabled):hover {
    background: rgba(255, 255, 255, .1);
  }
}

.btn-outline-secondary {
  @apply text-grey-3 border border-grey-3;

  &:not([disabled]):not(.disabled):hover {
    @apply text-white bg-grey-3;
  }
}

.btn-mask-icon {
  @apply text-grey-3;

  .icon {
    @apply bg-grey-3;
  }

  &:hover, &.active {
    @apply text-white;

    .icon {
      @apply bg-white;
    }
  }
}